<template>
    <div class="detail-quiz text-white">
        <div class="quiz mt-4">
            <div class="d-flex position-relative">
                <div class="col-md-8" ref="colQuiz">
                    <div id="header" class="header container-md">
                        <div class="card">
                            <div class="card-body px-0">
                                <div class="container-fluid p-0">
                                    <div class="row mx-4 align-items-center">
                                        <div class="col-sm-12 col-md-3">
                                            <img v-if="dataQuiz.images_cover == null " src="@/assets/images/default-cover.png" alt="" class="img-fluid img-header">
                                            <img v-else :src=this.dataQuiz.images_cover alt="" class="img-fluid img-header">
                                        </div>
                                        <div class="col-sm-12 col-md-9 text-left">
                                            <div class="ml-3 mt-1 d-flex justify-content-between">
                                                <div>
                                                <h2>{{ this.dataQuiz.quiz_name}}</h2>
                                                </div>
                                                <div class="d-flex">
                                                    <b-dropdown size="sm"  variant="link" toggle-class="text-decoration-none text-white p-0" no-caret>
                                                        <template #button-content>
                                                        <b-icon icon="three-dots-vertical"></b-icon>
                                                        </template>
                                                        <!-- <b-dropdown-item v-clipboard:copy="share(dataTrend._id, dataTrend.author_quiz)" v-clipboard:success="onCopy" v-clipboard:error="onError" class="d-flex align-items-center"><img src="@/assets/images/icons/share-icon.svg" alt="" class="user-svg mr-2">Share</b-dropdown-item> -->
                                                        <b-dropdown-item @click="share(dataQuiz._id, author)" class="d-flex align-items-center p-0"><img src="@/assets/images/icons/share-icon.svg" alt="" class="drop-svg mr-2">Share</b-dropdown-item>
                                                        <b-dropdown-item v-if="userLogin == true" @click="editTemplate(dataQuestion)" class="d-flex align-items-center p-0" ><img src="@/assets/images/icons/duplicate-icon.svg" alt="" class="drop-svg mr-2">Duplicate</b-dropdown-item>
                                                        <b-dropdown-item v-else @click="signInModal" class="d-flex align-items-center p-0" ><img src="@/assets/images/icons/duplicate-icon.svg" alt="" class="drop-svg mr-2">Duplicate</b-dropdown-item>
                                                        <b-dropdown-item v-if="userLogin == true" class="d-flex align-items-center p-0" @click="modalReport()"><img src="@/assets/images/icons/report-icon.svg" alt="" class="drop-svg mr-2">Report</b-dropdown-item>
                                                        <b-dropdown-item v-else class="d-flex align-items-center p-0" @click="signInModal()"><img src="@/assets/images/icons/report-icon.svg" alt="" class="drop-svg mr-2">Report</b-dropdown-item>
                                                    </b-dropdown>
                                                </div>
                                            </div>
                                            <div class="d-flex justify-content-between ml-3 mt-2">
                                                <div class="d-flex info-quiz">
                                                    <div class="d-flex mr-4">
                                                        <img
                                                            src="@/assets/images/icons/ic_play_grup_grey.svg"
                                                            alt=""
                                                            class="user-svg"
                                                        />
                                                        <p class="ml-1 text-white">{{dataQuiz.total_play}}</p>
                                                    </div>
                                                    <div class="d-flex mr-4">
                                                        <img
                                                            src="@/assets/images/icons/ic_question_grup_grey.svg"
                                                            alt=""
                                                            class="user-svg"
                                                        />
                                                        <p class="ml-1 text-white">{{ dataQuestion.length }}</p>
                                                    </div>
                                                    <div class="d-flex">
                                                            <img
                                                                src="@/assets/images/icons/ic_clock_grup_grey.svg"
                                                                alt=""
                                                                class="user-svg"
                                                            />
                                                        <p class="ml-1 text-white mb-0">{{getMinQuiz(dataQuestion.length)}} min</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="d-flex justify-content-between ml-3 pt-1">
                                                <div class="d-flex align-items-center">
                                                    <!-- USE TEMPLATE -->
                                                    <button type="button" v-if="userLogin == true" class="btn btn-light btn-grey-2 btn-md ml-2" @click="editTemplate(dataQuestion)"><img src="@/assets/images/icons/ic_use_template.svg" alt="duplicate" class="border-none"> Use Template</button>
                                                    <button type="button" v-else class="btn btn-light btn-grey-2 btn-md ml-2" @click="signInModal()"><img src="@/assets/images/icons/ic_use_template.svg" alt="duplicate" class="border-none"> Use Template</button>

                                                    <!-- FAVORITE -->
                                                    <button type="button" v-if="userLogin == true && favorite == false" @click="ratingQuiz(dataQuiz._id)" class="btn btn-light btn-grey-2 btn-md ml-2 d-flex">
                                                            <b-icon type="button" variant="" icon="star"></b-icon>
                                                            <p class="text-dark ml-1 mb-0">{{dataQuiz.rating ? dataQuiz.rating : '0'}}</p>
                                                    </button>
                                                    <button type="button" v-else-if="userLogin == true && favorite != false" @click="unRatingQuiz(dataQuiz._id)" class="btn btn-light btn-grey-2 btn-md ml-2 d-flex">
                                                            <b-icon type="button" variant="warning" icon="star-fill"></b-icon>
                                                            <p class="text-dark ml-1 mb-0">{{dataQuiz.rating ? dataQuiz.rating : '0'}}</p>
                                                    </button>
                                                    <button type="button" v-else @click="signInModal()" class="btn btn-light btn-grey-2 btn-md ml-2 d-flex">
                                                            <b-icon type="button" variant="warning" icon="star-fill"></b-icon>
                                                            <p class="text-dark ml-1 mb-0">{{dataQuiz.rating ? dataQuiz.rating : '0'}}</p>
                                                    </button>
                                                </div>
                                                <div class="creator-info d-flex align-items-center">
                                                    <img
                                                        v-if="author == '0'"
                                                        src="@/assets/images/logo/soca-logo.png"
                                                        alt=""
                                                        class="ml-2 rounded-circle"
                                                    />
                                                    <img v-else class="ml-2 rounded-circle" :src="dataQuiz.data_creator != null && dataQuiz.data_creator != '' ? dataQuiz.data_creator[0].picture : 'https://ui-avatars.com/api/?background=405DC3&color=fff&name='+dataQuiz.creator_name " alt="user">
                                                    <p v-if="author == '0'" class="text-white ml-1 mb-0 creator-name">{{ dataQuiz.author ? cutName(dataQuiz.author) : '-'}},</p>
                                                    <p v-else class="text-white ml-1 mb-0 creator-name">{{ dataQuiz.creator_name ? cutName(dataQuiz.creator_name) : '-'}},</p>
                                                    <p class="ml-1 mb-0 text-truncate mt-1 text-white mr-2" style="font-size: 8px">{{ getDate(dataQuiz.createdAt) }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <button type="button" v-if="userLogin == true" @click="playQuiz" class="btn btn-primary btn-block my-3 d-flex align-items-center justify-content-center btn-purple"> <img src="@/assets/images/icons/ic_play.svg" alt="play" class="border-none mr-2"><h3 class="my-2 font-weight-normal">Mainkan Sekarang</h3></button>
                        <button type="button" v-else @click="signInModal()" class="btn btn-primary btn-block my-3 d-flex align-items-center justify-content-center btn-purple"> <img src="@/assets/images/icons/ic_play.svg" alt="play" class="border-none mr-2"><h3 class="my-2 font-weight-normal">Mainkan Sekarang</h3></button>
                        
                        <div class="d-flex justify-content-between">
                            <div class="question-total d-flex align-items-center">
                                <img src="@/assets/images/icons/ic_document.svg" alt="question" class="border-none">
                                <h5 class="font-weight-normal ml-2">{{ this.dataQuestion.length }} Pertanyaan</h5>
                            </div>
                            <div v-if="dataQuiz.category_id != '63a3ba8202dc90ca59cb0715'" class="d-flex">
                                <label for="customSwitch1">Kunci Jawaban</label>
                                <div class="custom-control custom-switch show-answer ml-2">
                                    <input type="checkbox" class="custom-control-input" id="customSwitch1" v-model="showAnswers" v-if="userLogin == true">
                                    <input type="checkbox" class="custom-control-input" id="customSwitch2" @click="signInModal()" v-else>
                                    <label class="custom-control-label" for="customSwitch1" v-if="userLogin == true"></label>
                                    <label class="custom-control-label inactive" for="customSwitch2" v-else></label>
                                </div>
                            </div>
                        </div>

                        <div class="questions mt-4">
                            <div class="card mb-2" v-for="(data, index) in dataQuestion" :key="index" v-katex:auto>
                                <div class="d-flex align-items-center mx-4 my-3">
                                    <div class="col-md-6 text-left">
                                        <h5 class="font-weight-normal">{{ index + 1}}. {{ data.question_type == "Multiple Choice" || data.question_type == null || data.question_type == "" ? titleMultipleChoices : data.question_type == "True or False" ? titleTrueFalse : data.question_type == "Fill in the Blank" ? titleFillInTheBlank : titleMenjodohkan}}</h5>
                                    </div>
                                    <div class="col-md-6 d-flex justify-content-end">
                                        <div class="form-point mr-3">
                                            <p class="mb-0 my-1 mx-3">{{ data.point }} Points</p>
                                        </div>
                                        <div class="form-point">
                                            <p class="mb-0 my-1 mx-3">{{ data.duration }} Seconds</p>
                                        </div>
                                        <!-- <select name="" id="poin" v-model="data.point" class="form-control mr-3" disabled>
                                            <option :value="5">5 Points</option>
                                            <option :value="10">10 Points</option>
                                            <option :value="15">15 Points</option>
                                            <option :value="20">20 Points</option>
                                            <option :value="30">30 Points</option>
                                        </select>
                                        <select name="" v-model="data.duration" id="time" class="form-control mr-3" disabled>
                                            <option :value="10">10 Seconds</option>
                                            <option :value="30">30 Seconds</option>
                                        </select> -->
                                    </div>
                                </div>
                                <div class="px-4 py-3">
                                    <img v-if="data.image_url != null && data.image_url != ''" :src="data.image_url" :alt="data.image_url" class="img-fluid mb-3"/>
                                    <audio v-if="data.voice != null && data.voice != ''" :src="data.voice" controls></audio>
                                    <div v-if="data.video != null && data.video != ''" class="text-center w-100">
                                        <iframe width="50%" height="300" :src="data.video" title="YouTube video player" class="mt-2" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    </div>
                                    <p v-if="data.voice == null || data.voice == '' || data.video == null || data.video == ''" class="text-break text-left mb-0"><span v-html="data.question"></span></p>
                                </div>
                                <div class="text-left px-4 py-3">
                                    <div>
                                        <p class="text-secondary mb-0">Answers</p>
                                        <hr class="mt-1" style="background:#2D2D2D;">
                                    </div>
                                    <div class="answer">
                                        <div class="d-flex">
                                            <div class="col-12 text-left">
                                                <div v-if="showAnswers">
                                                    <div v-if="data.question_type == 'Multiple Choice' || data.question_type == 'Fill in the Blank' || data.question_type == null || data.question_type == ''" class="row justify-content-start px-3">
                                                        <!-- CUSTOM RADIO -->
                                                        <div class="col-6 justify-content-start mt-2">
                                                            <div class="d-flex">
                                                                <div>
                                                                    <span :class="data.correct_answer == '1' ? 'correct-active' : 'incorrect-active'"></span>
                                                                </div>
                                                                <p class="mb-0 ml-2 text-break d-flex"><span class="ml-1" v-html="data.option_1"></span></p>
                                                            </div>
                                                        </div>
                                                        <div class="col-6 justify-content-start mt-2">
                                                            <div class="d-flex">
                                                                <div>
                                                                    <span :class="data.correct_answer == '2' ? 'correct-active' : 'incorrect-active'"></span>
                                                                </div>
                                                                <p class="mb-0 ml-2 text-break d-flex"><span class="ml-1" v-html="data.option_2"></span></p>
                                                            </div>
                                                        </div>
                                                        <div v-if="data.option_3 != null && data.option_3 != ''" class="col-6 justify-content-start mt-2">
                                                            <div class="d-flex">
                                                                <div>
                                                                    <span :class="data.correct_answer == '3' ? 'correct-active' : 'incorrect-active'"></span>
                                                                </div>
                                                                <p class="mb-0 ml-2 text-break d-flex"> <span class="ml-1" v-html="data.option_3"></span></p>
                                                            </div>
                                                        </div>
                                                        <div v-if="data.option_4 != null && data.option_4 != ''" class="col-6 justify-content-start mt-2">
                                                            <div class="d-flex">
                                                                <div>
                                                                    <span :class="data.correct_answer == '4' ? 'correct-active' : 'incorrect-active'"></span>
                                                                </div>
                                                                <p class="mb-0 ml-2 text-break d-flex"><span class="ml-1" v-html="data.option_4"></span></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div v-else-if="data.question_type == 'True or False'" class="row justify-content-start px-3">
                                                        <!-- CUSTOM RADIO -->
                                                        <div class="col-6 justify-content-start mt-2">
                                                            <div class="d-flex">
                                                                <div>
                                                                    <span :class="data.correct_answer == '1' ? 'correct-active' : 'incorrect-active'"></span>
                                                                </div>
                                                                <p class="mb-0 ml-2 text-break d-flex"><span class="ml-1" v-html="data.option_1"></span></p>
                                                            </div>
                                                        </div>
                                                        <div class="col-6 justify-content-start mt-2">
                                                            <div class="d-flex">
                                                                <div>
                                                                    <span :class="data.correct_answer == '2' ? 'correct-active' : 'incorrect-active'"></span>
                                                                </div>
                                                                <p class="mb-0 ml-2 text-break d-flex"><span class="ml-1" v-html="data.option_2"></span></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-else>
                                                    <div v-if="data.question_type == 'Multiple Choice' || data.question_type == 'Fill in the Blank' || data.question_type == null || data.question_type == ''" class="row d-flex justify-content-start px-3">
                                                    <!-- CUSTOM RADIO -->
                                                        <div class="col-6 justify-content-start mt-2">
                                                            <div class="d-flex">
                                                                <div>
                                                                    <span class="answer-def"></span>
                                                                </div>
                                                            <p class="mb-0 ml-2 text-break d-flex"> <span class="ml-1" v-html="data.option_1"></span></p>
                                                            </div>
                                                        </div>
                                                        <div class="col-6 justify-content-start mt-2">
                                                            <div class="d-flex">
                                                                <div>
                                                                    <span class="answer-def"></span>
                                                                </div>
                                                            <p class="mb-0 ml-2 text-break d-flex"> <span class="ml-1" v-html="data.option_2"></span></p>
                                                            </div>
                                                        </div>
                                                        <div class="col-6 justify-content-start mt-2">
                                                            <div class="d-flex">
                                                                <div>
                                                                    <span class="answer-def"></span>
                                                                </div>
                                                            <p class="mb-0 ml-2 text-break d-flex"> <span class="ml-1" v-html="data.option_3"></span></p>
                                                            </div>
                                                        </div>
                                                        <div class="col-6 justify-content-start mt-2">
                                                            <div class="d-flex">
                                                                <div>
                                                                    <span class="answer-def"></span>
                                                                </div>
                                                            <p class="mb-0 ml-2 text-break d-flex"> <span class="ml-1" v-html="data.option_4"></span></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div v-else-if="data.question_type == 'True or False'"  class="row d-flex justify-content-start px-3">
                                                    <!-- CUSTOM RADIO -->
                                                        <div class="col-6 justify-content-start mt-2">
                                                            <div class="d-flex">
                                                                <div>
                                                                    <span class="answer-def"></span>
                                                                </div>
                                                            <p class="mb-0 ml-2 text-break d-flex"> <span class="ml-1" v-html="data.option_1"></span></p>
                                                            </div>
                                                        </div>
                                                        <div class="col-6 justify-content-start mt-2">
                                                            <div class="d-flex">
                                                                <div>
                                                                    <span class="answer-def"></span>
                                                                </div>
                                                            <p class="mb-0 ml-2 text-break d-flex"> <span class="ml-1" v-html="data.option_2"></span></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div v-else-if="data.question_type == 'Match'" class="col-12 container-fluid text-left">
                                                        <table style="width:50%" border>
                                                            <tr>
                                                                <td style="width:20%">
                                                                    <div class="d-flex">
                                                                        <div>
                                                                            <span class="answer-def"></span>
                                                                        </div>
                                                                        <p v-if="data.img_option_1 == null || data.img_option_1 == ''" class="mb-0 ml-2 text-break d-flex"><span class="text-break ml-1" v-html="data.option_1"></span></p>
                                                                        <img v-else :src="data.img_option_1" alt="img1" class="img-option ml-2">
                                                                    </div>
                                                                </td>
                                                                <td style="width:20%">
                                                                    <div class="d-flex">
                                                                        <div>
                                                                            <span class="answer-def"></span>
                                                                        </div>
                                                                        <p v-if="data.img_option_2 == null || data.img_option_2 == ''" class="mb-0 ml-2 text-break d-flex"><span class="text-break ml-1 " v-html="data.option_2"></span></p>
                                                                        <img v-else :src="data.img_option_2" alt="img2" class="img-option ml-2">
                                                                    </div>
                                                                </td>
                                                                <td style="width:20%">
                                                                    <div class="d-flex">
                                                                        <div>
                                                                            <span class="answer-def"></span>
                                                                        </div>
                                                                        <p v-if="data.img_option_3 == null || data.img_option_3 == ''" class="mb-0 ml-2 text-break d-flex"><span class="text-break ml-1 " v-html="data.option_3"></span></p>
                                                                        <img v-else :src="data.img_option_3" alt="img3" class="img-option ml-2">
                                                                    </div>
                                                                </td>
                                                                <td style="width:20%">
                                                                    <div class="d-flex">
                                                                        <div>
                                                                            <span class="answer-def"></span>
                                                                        </div>
                                                                        <p v-if="data.img_option_4 == null || data.img_option_4 == ''" class="mb-0 ml-2 text-break d-flex"><span class="text-break ml-1 " v-html="data.option_4"></span></p>
                                                                        <img v-else :src="data.img_option_4" alt="img4" class="img-option ml-2">
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style="width:20%">
                                                                    <div class="d-flex">
                                                                        <div>
                                                                            <span class="correct-active"></span>
                                                                        </div>
                                                                        <p class="mb-0 ml-2 text-break d-flex"><span class="text-break ml-1" v-html="data.match_1"></span></p>
                                                                    </div>
                                                                </td>
                                                                <td style="width:20%">
                                                                    <div class="d-flex">
                                                                        <div>
                                                                            <span class="correct-active"></span>
                                                                        </div>
                                                                        <p class="mb-0 ml-2 text-break d-flex"><span class="text-break ml-1" v-html="data.match_2"></span></p>
                                                                    </div>
                                                                </td>
                                                                <td style="width:20%">
                                                                    <div class="d-flex">
                                                                        <div>
                                                                            <span class="correct-active"></span>
                                                                        </div>
                                                                        <p class="mb-0 ml-2 text-break d-flex"><span class="text-break ml-1" v-html="data.match_3"></span></p>
                                                                    </div>
                                                                </td>
                                                                <td style="width:20%">
                                                                    <div class="d-flex">
                                                                        <div>
                                                                            <span class="correct-active"></span>
                                                                        </div>
                                                                        <p class="mb-0 ml-2 text-break d-flex"><span class="text-break ml-1" v-html="data.match_4"></span></p>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 h-100 position-absolute-0 similar-quiz overflow-auto">
                    <h3 class="text-left">Quiz Sejenis</h3>
                    <div class="h-100">
                        <div v-for="(data, index) in dataQuizSimilar" :key="index">
                            <div class="card mt-3" type="button" @click="detailQuiz(data._id, data.author_quiz, data.quiz_name)">
                                <div class="card-body">
                                    <div class="row align-items-center" type="button">
                                        <div class="col-md-4 text-left">
                                            <img v-if="data.images_cover != null" :src=data.images_cover alt="" class="img-fluid ml-2 card-img-top">
                                            <img v-else src="@/assets/images/default-cover.png" alt="" class="img-fluid ml-2 card-img-top">
                                        </div>
                                        <div class="col-md-8 text-left">
                                            <div class="container">
                                                <h4 class="ml-3">{{ data.quiz_name }}</h4>
                                                <!-- <div class="row mt-2 ml-3 mb-2">
                                                    <div class="col-3 align-items-center mr-2">
                                                        <div class="text-left">
                                                            <img
                                                                src="@/assets/images/icons/ic_play_grup_grey.svg"
                                                                alt=""
                                                                class="user-svg"
                                                            />
                                                            <p class="ml-1 text-white similar-p mb-0">{{ data.total_play }}</p>
                                                        </div>
                                                    </div>
                                                    <div class="col-3 align-items-center mr-2">
                                                        <div class="">
                                                            <img
                                                                src="@/assets/images/icons/ic_question_grup_grey.svg"
                                                                alt=""
                                                                class="user-svg"
                                                            />
                                                            <p class="similar-p mb-0 ml-1 text-white">{{ data.total_question }}</p>
                                                        </div>
                                                    </div>
                                                    <div class="col-3 align-items-center">
                                                        <div class=" d-flex justify-content-center align-items-center">
                                                           <img
                                                                src="@/assets/images/icons/ic_clock_grup_grey.svg"
                                                                alt=""
                                                                class="user-svg"
                                                            />
                                                        <p class="ml-1 text-white similar-p mb-0">{{getMinQuiz(data.total_question)}} <span style="font-size: 10px" class="text-truncate">min</span></p>
                                                        </div>
                                                    </div>
                                                </div> -->
                                                <div class="d-flex ml-3 my-3">
                                                    <div class="d-flex align-items-center">
                                                        <img
                                                            src="@/assets/images/icons/ic_play_grup_grey.svg"
                                                            alt="user"
                                                            class="user-svg"
                                                        />
                                                        <p class="ml-1 text-white similar-p mb-0">{{data.total_play}}</p>
                                                    </div>
                                                    <div class="d-flex align-items-center ml-3">
                                                        <img
                                                            src="@/assets/images/icons/ic_question_grup_grey.svg"
                                                            alt="user"
                                                            class="user-svg"
                                                        />
                                                        <p class="ml-1 text-white similar-p mb-0">{{data.total_question}}</p>
                                                    </div>
                                                    <div class="d-flex align-items-center ml-3">
                                                        <img
                                                            src="@/assets/images/icons/ic_clock_grup_grey.svg"
                                                            alt="user"
                                                            class="user-svg"
                                                        />
                                                        <p class="ml-1 text-white similar-p mb-0">{{getMinQuiz(data.total_question)}} min</p>
                                                    </div>
                                                </div>
                                                <div class="d-flex justify-content-between align-items-center ml-3">
                                                    <div class="creator-info-similar d-flex align-items-center">
                                                        <img
                                                            v-if="data.author_quiz == 0"
                                                            src="@/assets/images/logo/soca-logo.png"
                                                            alt=""
                                                            class="ml-2 rounded-circle"
                                                        />
                                                        <img v-else class="ml-2 rounded-circle" :src="data.data_creator[0].picture != null && data.data_creator[0].picture != '' ? data.data_creator[0].picture : 'https://ui-avatars.com/api/?background=405DC3&color=fff&name='+data.creator_name " alt="user">
                                                        <p v-if="data.author_quiz == 0" class="text-white ml-1 mb-0">{{ data.author ? cutName(data.author) : '-'}},</p>
                                                        <p v-else class="text-white ml-1 mb-0">{{ data.creator_name ? cutName(data.creator_name) : '-'}},</p>
                                                        <p class="ml-1 mb-0 text-truncate text-white mr-2" style="font-size: 10px">{{ getDate(dataQuiz.createdAt) }}</p>
                                                    </div>
                                                    <div class="d-flex ml-3">
                                                        <img src="@/assets/images/logo/start-icon.svg" alt="" class="icon-start border-none">
                                                        <p class="text-white ml-1 mb-0">{{data.rating ? data.rating : '0'}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- SIGN IN MODAL -->
        <b-modal id="sign-up-modal" centered header-class="border-0 pt-4 pb-0" :hide-footer="true" :hide-header="true" footer-class="border-0 pb-3 pt-0 mx-auto" size="md">
            <div class="text-center my-4 mx-4">
                <h3 class="font-weight-normal">Selamat Datang di</h3>
                <img src="@/assets/images/logo/soca-black.svg" alt="" class="my-3">
                <div class="card card-ggl mx-3 my-4">
                    <div class="card-body my-3">
                        <h4 class="font-weight-normal mb-3">Buat Akun Dengan</h4>
                        <button type="button" class="btn btn-light btn-ggl mt-2 mb-2" @click="signUpWithGoogle">
                            <div class="d-flex align-items-center mx-3">
                                <img src="@/assets/images/logo/google.svg" alt="" />
                                <p class="mb-0 ml-2">Daftar dengan Akun Google</p>
                            </div>
                        </button>
                        <p class="mb-0 mt-3 mb-4">Dengan melakukan daftar berarti Anda <br> menyetujui <a href="">syarat dan ketentuan</a> yang berlaku</p>
                        <p>
                            Sudah Mempunyai Akun ? <a href="#" @click="signInModal()">Masuk</a>
                        </p>
                    </div>
                </div>
            </div>
        </b-modal>

        <b-modal id="report-quiz" centered header-class="border-0 pt-4 pb-0" :hide-footer="true" :hide-header="true" footer-class="border-0 pb-3 pt-0 mx-auto" size="lg">
            <div class="d-flex justify-content-between my-4 mx-4">
                <div class="text-left d-flex align-items-center">
                    <img src="@/assets/images/icons/report-icon.svg" alt="" class="mr-3 mt-1">
                    <h3 class="font-weight-bold">Laporkan</h3>
                </div>
                <div class="text-right">
                    <button class="btn btn-close d-flex justify-content-center align-items-center" @click="$bvModal.hide('report-quiz')"><b-icon icon="x"></b-icon></button>
                </div>
            </div>
            <div class="mx-4">
                <div>
                    <h4>Mengapa kamu melaporkan quiz ini?</h4>
                    <div v-for="(data, index) in optionReport" :key="index" class="mt-3">
                        <div class="form-check d-flex align-items-center my-2">
                            <input class="form-check-input" type="radio" name="exampleRadios" :id="'report' + index" v-model="formReport.report" :value="data.text" checked>
                            <label class="form-check-label ml-2" :for="'report' + index">
                                <h5 class="font-weight-normal">{{ data.text }}</h5>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="pt-3">
                    <h4>Deskripsi</h4>
                    <div class="form-group">
                        <label for="deskripsiReport"></label>
                        <textarea v-model="formReport.detail_report" class="form-control" id="deskripsiReport" rows="3"></textarea>
                    </div>
                </div>
                <div class="float-right">
                    <div class="d-flex">
                        <button class="btn btn-secondary btn-grey mr-1 text-white" @click="$bvModal.hide('report-quiz')">Cancel</button>
                        <button class="btn btn-warning text-white" @click="reportQuizViolations()">Submit</button>
                    </div>
                </div>
                <!-- <b-form-group v-slot="{ ariaReport }">
                    <b-form-radio-group
                        size="sm"
                        v-model="reportSelected"
                        :options="optionReport"
                        :aria-describedby="ariaReport"
                        name="plain-stacked"
                        plain
                        stacked
                    ></b-form-radio-group>
                </b-form-group> -->
            </div>
        </b-modal>
    </div>
</template>

<script>
import {getQuizById, getQuizSimilar, getUserRatingTemplate, templateUserRating, templateUserUnrating, reportQuiz, getPlay, getEditTemplate, getUseTemplate } from '@/services/quiz/quiz.service'
import {getQuestions} from '@/services/question/question.service'
import {getQuizCreatorById, getUserRatingCreator, creatorUserRating, creatorUserUnrating} from '@/services/quiz-creator/quiz-creator.service'
import { getQuestionCreator } from '@/services/question-creator/question_creator.service'
import { getTokenByEmail, registerUser, updateDataUser, getProjects } from '@/services/projects/projects.service'
import { base_url_home } from '@/config/base_url'
import { mapGetters, mapActions } from "vuex";

import $ from "jquery";
import axios from "axios"
import Swal from 'sweetalert2';

export default {
    metaInfo() {
        return {
            title: this.dataQuiz.quiz_name,
            titleTemplate: '%s pertanyaan & jawaban untuk kuis dan lembar soal - Soca Quiz',
            meta : [
                {
                    name: 'twitter:title',
                    content: 'SOCA AI Quiz — The world’s most engaging learning platform',
                },
                {
                    
                    property: 'og:title',
                    content: 'SOCA AI Quiz — The world’s most engaging learning platform',
                }
            ]
        }
    },
    data(){
        return {
            dataQuiz : {
                _id: null,
                category_id: null,
                quiz_name: null,
                author:null,
                images_cover:null,
                categories_quiz: {
                    category_name: null
                } 
            },
            dataQuizSimilar: [],
            dataQuestion:[],
            showAnswers: false,
            showModal : false,
            userLogin : false,
            radios1:'luis',
            userAuth : "",
            userId : null,
            favorite : false,
            listQuestion: [],
            author_quiz : null,
            reportSelected : 1,
            formReport : {
                origin_quiz : null,
                report : null,
                detail_report : null,
            },
            optionReport : [
                {
                    text : 'Pelanggaran hak kekayaan intelektual',
                    value : 1,
                },
                {
                    text : 'Ujaran atau simbol kebencian',
                    value : 2,
                },
                {
                    text : 'Isi konten berisi tidak pantas',
                    value : 3,
                },
                {
                    text : 'Lainnya',
                    value : 4,
                },
            ],
            titleMultipleChoices : "Pilihan Ganda",
            titleTrueFalse : "Benar atau Salah",
            titleFillInTheBlank : "Short Answers",
            titleMenjodohkan : "Menjodohkan",
            offsetHeight : null
        }
    },

    computed: {
        ...mapGetters({ author : "author" }),
    },

    mounted() {
        this.getParamsRouteDynamic()
        this.setSideBar()
        const id = this.$route.params.id
        const author = this.author
        this.getAuthors(author)
        this.getDetailQuiz(id)
        this.getSimilarQuiz(id)
        this.getQuestions(id)
        $("div.detail-quiz").parent().addClass("m-0");
        // this.getRating(id, author)
        // this.getParamsRouteDynamic(id,author)
        
        let users = localStorage.getItem("users")

        if(users){
            let userParse = JSON.parse(users)
            this.userLogin = true;
            this.userId = userParse._id
            this.getRating(id, author)
        }

        if(this.userLogin == true){
            this.favorite = false
        } else {
            this.favorite = true
        }


        this.axiosInstance = axios.create({
            headers : ""
        })
    },
    methods : {
        ...mapActions(["setAuthor"]),

        getAuthors(author){
            this.author_quiz = author
        },

        setSideBar() {
            this.$store.commit("IS_SIDEBAR_ACTIVE", false);
        },

        getParamsRouteDynamic() {
            var split_url = window.location.href;
            var res = split_url.split("/");
            
            if(res[6] == 0 || res[6] == 1){
                this.setAuthor(res[6])
                this.$router.push({
                    name: "Detail-Quiz",
                    params: { id : res[5], name : res[7].replace(/ /g, '-') },
                });
            }
        },

        getDetailQuiz(id){
            const author = this.author
            let usersLogin = localStorage.getItem("users");
            let usersNotLogin = sessionStorage.getItem("users_id")

            let data = {}
            if(usersLogin){
                let parseUser = JSON.parse(usersLogin);

                data = { user_id : parseUser._id};
            } else if(usersNotLogin) {
                data = { user_not_login_id : usersNotLogin};
            } else {
                data = { };
            }

            if(author == 0) {
                getQuizById(id, data)
                .then(response => {
                    if(response.status && response.status == true){    
                        this.dataQuiz = response.data[0]
                    }
                }).catch(error => {
                    console.log(error)
                })
            } else {
                getQuizCreatorById(id, data)
                .then(response => {
                    if(response.status && response.status == true){    
                        this.dataQuiz = response.data[0]
                    }
                }).catch(error => {
                    console.log(error)
                })
            }
        },
        getQuestions(id){
            const author = this.author
            if(author == 0){
                getQuestions(id).then((response)=>{
                if(response.status){
                    this.$vs.loading.close();
                    this.dataQuestion = response.data;
                    this.offsetHeight = document.getElementById('header').clientHeight;
                }
                }).catch((err)=>{
                    this.$vs.loading.close();
                    console.log("data err", err)
                })
            } else {
                getQuestionCreator(id)
                .then((response)=>{
                    if(response.status){
                        this.$vs.loading.close();
                    this.dataQuestion = response.data;
                    }
                })
                .catch((err)=>{
                    this.$vs.loading.close();
                    console.log("data err", err)
                })
            }
        },
        playQuiz(){
            const author = this.author
            const id = this.$route.params.id
            getProjects()
            .then((resp) =>{
                if(resp.status == true){
                    if(author == 0){
                        let origin = { origin: 'template' }
                        getPlay(id,origin)
                        .then((response) => {
                            if(response.status && response.status == true){
                                console.log(response)
                                window.location = "https://play.soca.ai/instance/template/" + id + "/" + resp.data._id + "/" + this.dataQuiz.category_id
                            }
                        })
                    } else if (author == '1'){
                        let origin = { origin: 'creator' }
                        getPlay(id,origin)
                        .then((response) => {
                            if(response.status && response.status == true){
                                window.location = "https://play.soca.ai/instance/creator/" + id + "/" + resp.data._id + "/" + this.dataQuiz.category_id
                                console.log(response)
                            }
                        })
                    }
                }
            })
        },
        getSimilarQuiz(id){
            this.$vs.loading({
                type: 'sound'
            });
            getQuizSimilar(id)
            .then(response => {
                if(response.status && response.status == true){  
                    if(response.data.length > 0){
                        let datTemplate = response.data[0]

                        datTemplate.forEach((e) => {
                            this.dataQuizSimilar.push(e)
                        })

                        if(response.data.length > 1){
                            let datCreator = response.data[1]
                            datCreator.forEach((e) => {
                                this.dataQuizSimilar.push(e)
                            })
                        }
                    }  
                    this.$vs.loading.close();
                } else {
                    this.$vs.loading.close();
                }
            }).catch(error => {
                this.$vs.loading.close();
                console.log(error)
            })
        },
        detailQuiz(id, author, name) {
            this.setAuthor(author)

            this.$router.push({
                name: "Detail-Quiz",
                // query: { key: id, author: author },
                params: { id : id, name : name.replace(/ /g, '-') },
            });
        },
        editTemplate(data){
            var id = this.dataQuiz._id
            
            if(this.userLogin == true){
                const author = this.author
                if(author == 0){
                    let origin = { origin: 'template' }
                    getEditTemplate(id, origin)
                    .then((response) =>{
                        if(response.status && response.status == true){
                            if(localStorage.getItem("quizTime")){
                                localStorage.removeItem("quizTime")
                            }
                            if(localStorage.getItem("sisaWaktu")){
                                localStorage.removeItem("sisaWaktu")
                            }

                            localStorage.quiz_name = this.dataQuiz.quiz_name
                            localStorage.quiz_description = this.dataQuiz.description != undefined ? this.dataQuiz.description : ""
                            localStorage.images_cover = this.dataQuiz.images_cover
                            localStorage.category_id = this.dataQuiz.category_id

                            localStorage.removeItem("quiz_name")
                            localStorage.removeItem("quiz_description")
                            localStorage.removeItem("images_cover")
                            localStorage.removeItem("category_id")
                            localStorage.removeItem("createTag")
                            localStorage.removeItem("grade")
                            localStorage.removeItem("sub_grade")
                            localStorage.removeItem("index_grade")

                            this.$router.push({ name: 'Create-My-Quiz', query: {idTemp: id, author: author} })
                            localStorage.setItem('listQuestionCreate', JSON.stringify(data));   
                        }
                    })
                } else {
                    let origin = { origin: 'creator' }
                    getEditTemplate(id, origin)
                    .then((response) =>{
                        if(response.status && response.status == true){
                            if(localStorage.getItem("quizTime")){
                                localStorage.removeItem("quizTime")
                            }
                            if(localStorage.getItem("sisaWaktu")){
                                localStorage.removeItem("sisaWaktu")
                            }

                            localStorage.quiz_name = this.dataQuiz.quiz_name
                            localStorage.quiz_description = this.dataQuiz.description != undefined ? this.dataQuiz.description : ""
                            localStorage.images_cover = this.dataQuiz.images_cover
                            localStorage.category_id = this.dataQuiz.category_id

                            localStorage.removeItem("quiz_name")
                            localStorage.removeItem("quiz_description")
                            localStorage.removeItem("images_cover")
                            localStorage.removeItem("category_id")
                            localStorage.removeItem("createTag")
                            localStorage.removeItem("grade")
                            localStorage.removeItem("sub_grade")
                            localStorage.removeItem("index_grade")

                            this.$router.push({ name: 'Create-My-Quiz', query: {idTemp: id, author: author} })
                            localStorage.setItem('listQuestionCreate', JSON.stringify(data));   
                        }
                    })
                }
            } else {
                this.signIn()
            }
        },
        reportQuizViolations(){
            const author = this.author
            const id = this.$route.params.id
            if(author == 0){
                this.formReport.origin_quiz = "template"
            } else {
                this.formReport.origin_quiz = "creator"
            }
            reportQuiz(id, this.formReport)
            .then((response) => {
                if(response.status && response.status == true){
                    this.$bvModal.hide('report-quiz')
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Report sukses !',
                        showConfirmButton: false,
                        timer: 1000
                    })
                    this.formReport = {
                        origin_quiz : null,
                        report : null,
                        detail_report : null,
                    },
                    console.log(response)
                } else {
                    this.$bvModal.hide('report-quiz')
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: 'Report gagal !',
                        showConfirmButton: false,
                        timer: 1000
                    })
                    this.formReport = {
                        origin_quiz : null,
                        report : null,
                        detail_report : null,
                    },
                    console.log(response)
                }
            })
        },
        modalReport(){
            this.$bvModal.show('report-quiz')
        },
        signInModal(){
            this.$bvModal.show('signIn')
        },
        signUp(){
            this.$bvModal.hide('sign-in-modal')
            this.$bvModal.show('sign-up-modal')
        },
        signInWithGoogle(){
            var vm = this
            const client = window.google.accounts.oauth2.initTokenClient({
                client_id: process.env.VUE_APP_SECRET_KEY_GOOGLE,
                scope: 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
                callback: (response) => {
                    this.axiosInstance.get("https://www.googleapis.com/oauth2/v3/userinfo", {
                        headers: {
                         Authorization: `Bearer ${response.access_token}`
                        }
                    })
                    .then((res) => {
                        const email = res.data.email;
                        this.updateUser(email, res)
                        return getTokenByEmail(email);
                    }).then((res) => {
                        if(res.status) {
                                window.location = base_url_home + 'templates';
                            } else {
                                vm.$bvModal.show('email-tidak-terdaftar-modal')
                            }
                    })
                    
                },
            });

            client.requestAccessToken();
        },
        signUpWithGoogle() {
            const client = window.google.accounts.oauth2.initTokenClient({
                client_id: process.env.VUE_APP_SECRET_KEY_GOOGLE,
                scope: 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
                callback: (response) => {
                    this.axiosInstance.get("https://www.googleapis.com/oauth2/v3/userinfo", {
                        headers: {
                            Authorization: `Bearer ${response.access_token}`
                        }
                    })
                    .then((res) => {
                        this.registerUser(res)
                    })
                },
            });

            client.requestAccessToken();
        },
        // getParamsRouteDynamic(id,author){
        //     var split_url = window.location.href
        //     var res = split_url.split("/")
        //     this.userAuth = res[3]
        //     this.userId = res[4]
        //     if(this.userLogin == true){
        //         this.favorite = false
        //     } else {
        //         this.favorite = true
        //     }

        // },
        signIn(){
            this.$emit('signIn')
        },
        useTemplate(){
            var id = this.dataQuiz._id
            const author = this.author

            if(author == 0){
                let origin = { origin: 'template' }

                getUseTemplate(id, origin)
                .then((response) =>{
                    if(response.status && response.status == true){
                        const length = this.dataQuestion.reduce((totalDuration, question) => {
                            return totalDuration + (question.duration || 30);
                        }, 0);

                        if(localStorage.getItem("quizTime")){
                            localStorage.removeItem("quizTime")
                        }
                        if(localStorage.getItem("sisaWaktu")){
                            localStorage.removeItem("sisaWaktu")
                        }
                        localStorage.setItem("quizTime", length)
                        this.$router.push({ name : 'Use-Template', query: {key: id, creator: false} })
                    }
                })
            } else {
                let origin = { origin: 'creator' }

                getUseTemplate(id, origin)
                .then((response) =>{
                    if(response.status && response.status == true){
                        const length = this.dataQuestion.reduce((totalDuration, question) => {
                            return totalDuration + (question.duration || 30);
                        }, 0);

                        if(localStorage.getItem("quizTime")){
                            localStorage.removeItem("quizTime")
                        }
                        if(localStorage.getItem("sisaWaktu")){
                            localStorage.removeItem("sisaWaktu")
                        }
                        localStorage.setItem("quizTime", length)
                        this.$router.push({ name : 'Use-Template', query: {key: id, creator: true} })
                    }
                })
            }

        },
        updateUser(email, user_data){
            var dataPost = {
                email: user_data.data.email,
                name : user_data.data.name,
                picture: user_data.data.picture
            }
            updateDataUser(email, dataPost).then((response) => {
                console.log(response)
            })
        },
        registerUser(user_data) {
            var dataPost = {
                name: user_data.data.name,
                email: user_data.data.email
            }

            var vm = this

            new Promise(function(resolve) {
                setTimeout(() => resolve(registerUser(dataPost)))
            })
            .then(function(result) {
                if(!result.status) {
                    vm.$bvModal.show('akun-sudah-terdaftar-modal')
                } else {
                    vm.$bvModal.show('email-berhasil-terdaftar-modal')
                }
            }) 
        },
        cutName(item){
            const name = item.split(" ")
            return name[0]
        },
        getDate(item){
            var publish = new Date(item);

            var seconds = Math.floor((new Date() - publish) / 1000);

            var interval = seconds / 31536000;

            if (interval > 1) {
                return Math.floor(interval) + " years ago";
            }
            interval = seconds / 2592000;
            if (interval > 1) {
                return Math.floor(interval) + " months ago";
            }
            interval = seconds / 86400;
            if (interval > 1) {
                return Math.floor(interval) + " days ago";
            }
            interval = seconds / 3600;
            if (interval > 1) {
                return Math.floor(interval) + " hours ago";
            }
            interval = seconds / 60;
            if (interval > 1) {
                return Math.floor(interval) + " minutes ago";
            }
            return Math.floor(seconds) + " seconds ago";
        },

        share(id, author){
            this.linkShare ='https://creator.soca.ai/quiz/detail-quiz?key=' + id + '&author=' + author

            this.$copyText(this.linkShare).then(function () {
                alert('Berhasil copy link')
                }, function () {
                alert('Gagal untuk copy link')
                })
            // return this.linkShare
        },

        getMinQuiz(length){
            
            let time = length * 30

            let min = Math.floor(time / 60);

            return min
        }, 

        getRating(id, author){
            if(author == 0) {
                getUserRatingTemplate(id)
                .then((response) => {
                    if(response.status){
                        if(response.data == 0){
                            this.favorite = false
                        } else {
                            this.favorite = true
                        }
                    }
                })
            } else {
                getUserRatingCreator(id)
                .then((response) => {
                    if(response.status){
                        if(response.data == 0){
                            this.favorite = false
                        } else {
                            this.favorite = true
                        }
                    }
                })
            }
        },
        
        ratingQuiz(id){
            const author = this.author
            if(author == 0) {
                templateUserRating(id)
                .then((response) => {
                    if(response.status && response.status == true){
                        this.getRating(id, 0)
                        this.getDetailQuiz(id)
                        this.favorite = true
                    }
                })
            } else {
                creatorUserRating(id)
                .then((response) => {
                    if(response.status && response.status == true){
                        this.getRating(id, 1)
                        this.getDetailQuiz(id)
                        this.favorite = true
                    }
                })
            }
        },

        unRatingQuiz(id){
            const author = this.author
            if(author == 0){
                templateUserUnrating(id)
                .then((response) => {
                    if(response.status && response.status == true){
                        this.getRating(id, 0)
                        this.getDetailQuiz(id)
                        this.favorite = false
                    }
                })
            } else {
                creatorUserUnrating(id)
                .then((response) => {
                    if(response.status && response.status == true){
                        this.getRating(id, 1)
                        this.getDetailQuiz(id)
                        this.favorite = false
                    }
                })
            }
        },

        toTerm(){
            this.$router.push({ name: 'Terms-Conditions'})
        }
        
    }
}
</script>

<style scoped>
h2{
    font-size: 26px;
}

.info-quiz p{
    font-size: 16px;
}

.border-none{
    border-radius:0 !important
}
.detail-quiz img, .detail-quiz .card{
    border-radius: 15px;
}

table, th, td {
     border: 0;
}

tr,td{
    padding-top: 1rem;
}

.form-point{
    width: auto;
    background-color:#8C8C8C;
    border-radius:4px ;
}

.img-option{
    height: 3.5rem;
    width: 3.75rem;
    border-radius: 6px;
}

.border-none {
    border-radius: 0 !important;
}

.btn-ggl{
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 25px;
    background: white;
}

.card-ggl{
    border-radius: 20px;
}

.correct-active{
    height: 1.125rem;
    width: 1.125rem;
    border-radius: 50%;
    display: inline-block;
    background-color: #40BE45;
}

.incorrect-active{
    height: 1.125rem;
    width: 1.125rem;
    border-radius: 50%;
    display: inline-block;
    background-color: #EB5757;
}

.answer-def{
    height: 1.125rem;
    width: 1.125rem;
    border-radius: 50%;
    display: inline-block;
    background-color: #D9D9D9;
}

.user-svg{
    width: 22px;
    height: 20px;
}

.paper-svg, .time-square-svg{
    width: 11px;
    height: 13.55px;
}

.quiz .text-none {
    color: #8C8C8C;
    margin: 0;
    font-size: 12px;
}

.text-purple{
    color : #6D75F6;
}

.too-long{
    overflow: hidden;
    white-space: nowrap; /* it's Must declared */
    text-overflow: ellipsis;
}

.card-info{
  width: 23px;
  height: 23px;
  border-radius: 50%;
  background-color: #F1F1F1;
}

.card-info-similar{
  width: 23px;
  height: 23px;
  border-radius: 50%;
  background-color: #E8E8E8;
}

.creator-info{
  border-radius: 17px;
  height: 30px;
  width: auto;
  background-color: #8C8C8C;
}

.creator-info-similar{
  border-radius: 12px;
  height: 30px;
  width: auto;
  background-color: #8C8C8C;
}

.creator-info img, .creator-info-similar img {
  width: 18px;
  height: 18px;
}

.icon-start{
  width: 18px;
  height: 18px;
}
.img-header{
    width: 15rem !important;
    height : 12rem !important;
}

.drop-svg{
    width: 30px;
    height: 15px;
}

.creator-name{
    font-size: 10px !important;
}

.btn-close{
    background-color: #EDEDED;
    border-radius: 50%;
    width: 35px;
    height: 35px;
}

.btn-grey{
    background-color: #EDEDED;
    border-color : #EDEDED;
}

.btn-grey-2{
    background-color: #ECECEC;
    border-color: #ECECEC;
}

.btn-purple {
    background-color: #6D75F6;
    border: 1px solid #6D75F6;
}

#report-quiz h3{
    font-size: 24px;
}

#report-quiz h4{
    font-size: 20px;
}

#report-quiz h5{
    font-size: 16px;
}

#report-quiz img{
    height: 24px;
    width: 20px;
}


#report-quiz input[type="radio"]{
    width: 22px;
    height: 22px;
}

#report-quiz .card, #report-quiz textarea{
    border-radius : 10px !important;
}

.card-img-top{
    width: 100%;
    height: 8.5rem;
    border-radius: 10px 10px 10px 10px;
}

.inactive::before {
    left: -2.25rem !important;
    width: 1.75rem !important;
    pointer-events: all !important;
    border-radius: 0.5rem !important;
    position: absolute !important;
    top: 0.13438rem !important;
    display: block !important;
    height: 1rem !important;
    content: "" !important;
    background-color: #fff !important;
    border: #adb5bd solid 1px !important;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}

.inactive::after {
    transform: translateX(0rem) !important;
    top: calc(0.13438rem + 2px) !important;
    left: calc(-2.25rem + 2px) !important;
    width: calc(1rem - 4px) !important;
    height: calc(1rem - 4px) !important;
    background-color: #adb5bd !important;
    border-radius: 0.5rem !important;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}

.position-absolute-0{
    position: absolute;
    right: 0;
}

.similar-quiz::-webkit-scrollbar{
    display: none !important;
}

.similar-quiz{
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

@media (max-width: 1366px){
    .card-info-similar{
        width: 23px;
        height: 23px;
        border-radius: 50%;
        background-color: #E8E8E8;
    }

    .mc-t{
        margin-top: 0.5rem;
    }

    .card-info-similar p{
        font-size: 10px;
    }

    .creator-info-similar{
        border-radius: 12px;
        height: 25px;
        width: 100%;
        background-color: #E8E8E8;
    }

    .similar-quiz h4{
        font-size: 15px;
    }

    .icon-start{
        width: 16px;
        height: 16px;
    }

    .creator-info-similar p{
        font-size: 10px;
    }

    .similar-p{
        font-size: 11px;
    }

    .creator-info{
        border-radius: 12px;
        height: 25px;
        width: auto;
        background-color: #F1F1F1;
    }

    .card-img-top{
        width: 100%;
        height: 8.5rem;
        border-radius: 10px 10px 10px 10px;
    }
}
</style>

<style>
.custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #40BE45;
    background-color: #40BE45;
}
</style>