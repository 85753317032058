import { render, staticRenderFns } from "./detailQuiz.vue?vue&type=template&id=778ce3db&scoped=true&"
import script from "./detailQuiz.vue?vue&type=script&lang=js&"
export * from "./detailQuiz.vue?vue&type=script&lang=js&"
import style0 from "./detailQuiz.vue?vue&type=style&index=0&id=778ce3db&prod&scoped=true&lang=css&"
import style1 from "./detailQuiz.vue?vue&type=style&index=1&id=778ce3db&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "778ce3db",
  null
  
)

export default component.exports